import * as React from "react";
import { graphql } from "gatsby";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";

import { Box } from "@components/box";
import { PageLayout, SEO } from "@components/page-layout";
import {
  GrayPhrase,
  PageHeading,
  Paragraph
} from "@components/typography";
import { INLINES } from "@contentful/rich-text-react-renderer/node_modules/@contentful/rich-text-types";
import {documentToReactComponents} from '@contentful/rich-text-react-renderer'
import { GatsbyLink } from "@components/gatsby-link";

const convertToSlug = (text: string) => {
  return text
    .toString()
    .toLowerCase()
    .replace(/ /g,'-')
    .replace(/[^\w-]+/g,'');
}

const TermsOfUseTemplate = (props: any) => {
  const { contentfulTermsOfUse }: any = props.data;

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
        const imageID = node.data.target.sys.id;
        const {
          file: { url },
        } = contentfulTermsOfUse.body.references.find(
          ({ contentful_id }: { contentful_id: string | number }) =>
            contentful_id === imageID
        );

        return (
          <Box display="flex" className="w-full justify-center py-[2rem]">
            <img className="w-4/5 md:w-full" src={url} />
          </Box>
        );
      },
      [BLOCKS.HEADING_1]: (_node: any, children: any) => (
        <Box className="py-[2rem]">
          <PageHeading>{children}</PageHeading>
        </Box>
      ),
      [BLOCKS.HEADING_2]: (_node: any, children: any) => (
        <h2 className="global--subheading mb-[2rem] md:mb-[11px] mt-[33px] md:mt-[1.5rem] first:mt-0" id={convertToSlug(children)}>{children}</h2>
      ),
      [BLOCKS.HEADING_3]: (_node: any, children: any) => (
        <h3 className="global--paragraph font-500 pb-[22px]">{children}</h3>
      ),
      [BLOCKS.PARAGRAPH]: (_node: any, children: any) => (
        <Paragraph className="text-interface-300 mb-[22px] whitespace-pre-wrap">{children}</Paragraph>
      ),
      [INLINES.HYPERLINK]: (_node: any, children: any) => (
        <GatsbyLink
          target="_blank"
          to={_node.data.uri}
          rel={
            _node.data.uri.startsWith("https://icash.ca")
              ? "noopener"
              : "noopener nofollow"
          }
        >
          {children}
        </GatsbyLink>
      ),
      [BLOCKS.UL_LIST]: (_node: any, children: any) => (<ul className="mb-[22px] pl-[16px]">{children}</ul>),
      [BLOCKS.OL_LIST]: (_node: any, children: any) => (<ol className="mb-[22px] pl-[16px]">{children}</ol>),
      [BLOCKS.LIST_ITEM]: (_node: any, children: any) => {
        const transformedChildren = documentToReactComponents(_node, {
          renderNode: {
            [BLOCKS.PARAGRAPH]: (_node: any, children: any) => children,
            [BLOCKS.LIST_ITEM]: (_node: any, children: any) => children,
          },
        });
        return <li><span className="global--paragraph text-interface-300">{transformedChildren}</span></li>;
      }
    },
  };

  const optionsNav = {
    renderNode: {
      [INLINES.HYPERLINK]: (_node: any) => null,
      [BLOCKS.HEADING_1]: (_node: any) => null,
      [BLOCKS.HEADING_3]: (_node: any) => null,
      [BLOCKS.PARAGRAPH]: (_node: any) => null,
      [BLOCKS.UL_LIST]: (_node: any) => null,
      [BLOCKS.OL_LIST]: (_node: any) => null,
      [BLOCKS.LIST_ITEM]: (_node: any) => null,
      [BLOCKS.QUOTE]: (_node: any) => null,
      [BLOCKS.HR]: (_node: any) => null,
      [BLOCKS.HEADING_2]: (_node: any, children: any) => (
        <GatsbyLink className="font-500 text-interface-400 mb-[15px] md:mt-[10px] first:text-interface-500" to={`#${convertToSlug(children)}`} inheritStyle>
          {children}
        </GatsbyLink>
      )
    },
    renderMark: {
      [MARKS.BOLD]: (_node: any) => null,
      [MARKS.ITALIC]: (_node: any) => null,
      [MARKS.UNDERLINE]: (_node: any) => null,
      [MARKS.CODE]: (_node: any) => null
    }
  };

  return (
    <PageLayout>
      <SEO title={contentfulTermsOfUse.metaTitle} description={contentfulTermsOfUse.metaDescription.metaDescription} />
      <Box display="flex" className="justify-center mt-[8.25rem] md:mt-[4rem]">
        <Box
          display="flex"
          className="flex-col max-w-[1320px] justify-center px-[22px] flex-wrap"
        >
          <Box display="flex" className="flex-col items-center">
            <Box className="mx-auto mb-[5.5rem] md:mb-2 text-center">
              <PageHeading className="mb-[1.5rem]">{contentfulTermsOfUse.h1}</PageHeading>
              <Paragraph className="mx-auto max-w-[720px]">
                <GrayPhrase>{contentfulTermsOfUse.smallDescription.smallDescription}</GrayPhrase>
              </Paragraph>
            </Box>
          </Box>
          <Box className="self-end w-full text-[15px] text-right text-interface-300 pb-[2.75rem] md:pb-0 mr-[-35%] lg:mr-[-20%] md:mr-0">
            Last updated {contentfulTermsOfUse.lastUpdate}
          </Box>
        </Box>
      </Box>
      <Box display="flex" className="mb-[8.25rem] md:mb-[4rem] justify-center">
        <Box display="flex" className="relative gap-x-[68px] md:flex-col md:w-auto md:mx-[2.75rem] sm:mx-[22px]">
          <Box className="absolute left-[-238px] w-[168px] lg:relative lg:left-0 md:w-full md:my-[2.75rem] md:bg-interface-100 md:p-[1.75rem] md:rounded-[30px]">
            <Box display="flex" className="flex-col space-y-1.25">
              {renderRichText(contentfulTermsOfUse.content, optionsNav)}
            </Box>
          </Box>
          <Box display="flex" className="relative w-full flex-col max-w-[720px] justify-center">
            {renderRichText(contentfulTermsOfUse.content, options)}
          </Box>
        </Box>
      </Box>
    </PageLayout>
  );
};

export default TermsOfUseTemplate;

export const pageQuery = graphql`
  query TermsOfUseBySlug($slug: String!) {
    contentfulTermsOfUse(slug: { eq: $slug }) {
      content {
        raw
      }
      slug
      h1
      metaTitle
      metaDescription {
        metaDescription
      }
      lastUpdate(formatString: "MMMM DD, YYYY")
      smallDescription {
        smallDescription
      }
      updatedAt
    }
  }
`;